<template>
  <div v-if="platformPermissionsLoaded && checkPermission('loyalty.insights.store') && ['business', 'enterprise'].includes(productLicenses.loyalty_license_tag)">
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Loyalty_insights_store2')}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top mr-2">
              <CButton class="m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="m-0" color="primary" @click="exportData();">
                <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('insights.Save_as_json') }}</span>              
              </CButton>
            </div>            
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow class="insights_filters">
      <CCol cols="2" xl="2" lg="3" md="3" sm="12">
        <b-datepicker v-model="dateFrom"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getInsightsData();"
                      class="d-inline-block align-middle">
          <CButton color="primary" @click="dateFrom = new Date(); getInsightsData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol cols="2" xl="2" lg="3" md="3" sm="12">
        <b-datepicker v-model="dateTo"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getInsightsData();"
                      class="d-inline-block align-middle">
          <CButton color="primary" @click="dateTo = new Date(); getInsightsData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol cols="3" xl="3" lg="3" md="3" sm="12">
        <multiselect
          class="data_table open_absolute multiple"
          v-model="selectedTeams"
          :options="departments" 
          :multiple="true"
          :hide-selected="true"
          :group-select="true"
          group-values="teams" 
          group-label="department_name"
          :placeholder="$t('common.Filter_on_team')"
          :selectLabel="$t('common.Add_team')"
          track-by="team_id" 
          label="team_name"
          @input="getInsightsData();">
          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
        </multiselect> 
      </CCol>
    </CRow>

    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">
      <CCol cols="3" xl="3" lg="3" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('common.Coins_spent')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <PointsCount v-else
                         mode="with_description"
                         :points="insightsData.metrics[0].user_points_spent"
                         :pointsTitle="$t('By') + ' ' + insightsData.metrics[0].headcount_user_points_spent + ' ' + $t('insights.employees')"
                         :environmentTag="environmentTag">
            </PointsCount>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Orders')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else
                   mode="with_description"
                   :count="insightsData.metrics[0].orders_amount"
                   :countTitle="$t('For') + ' ' + insightsData.metrics[0].orders_amount_of_products + ' ' + $t('insights.products')"
                   :countIcon="null">
            </Count>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('common.Average_coins_spent')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>                        
            <PointsCount v-else
                         mode="with_description"
                         :points="(insightsData.metrics[0].user_points_spent / insightsData.headcount.headcount_total).toFixed(0)"
                         :pointsTitle="$t('By') + ' ' + insightsData.headcount.headcount_total + ' ' + $t('insights.employees')"
                         :environmentTag="environmentTag">
            </PointsCount>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="12" sm="12">
        <CCard class="mb-0">
          <CCardHeader v-bind:class="{'pb-0': insightsData.categories.length > 0}">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Favorite_categories')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !loadingInsightsData && insightsData.categories.length > 0}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <div v-else>
              <PieChart v-if="insightsData.categories.length > 0" :pieChartSeries="pieChartSeries" pieChartHeight="200px" :companyPrimaryColor="companyPrimaryColor"/>
              <div v-else class="text-center">
                <span>{{$t('insights.No_categories_found')}}</span>
              </div>
            </div>
          </CCardBody>
        </CCard>        
      </CCol>   

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('common.Coints_spent_trend')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <LineChart v-else :lineChartSeries="lineChartSeries" xAxisType="datetime" yAxisType="int" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>        
      </CCol> 

      <CCol cols="8" xl="8" lg="8" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0">
                {{$t('insights.Products')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <ProductsTable v-else :products="insightsData.products" :environmentTag="environmentTag"/>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Popular_products')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <ProductHighlightStage v-else :stageData="insightsData.products" :scoreValue="'orders_amount'"/>
          </CCardBody>
        </CCard>        
      </CCol>      
    </CRow> 
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import Multiselect from 'vue-multiselect';

import Count from '@/components/insights/common/Count.vue';
import LineChart from '@/components/insights/common/LineChart.vue';
import PieChart from '@/components/insights/common/PieChart.vue';

import ProductsTable from '@/components/insights/loyalty/ProductsTable.vue';
import ProductHighlightStage from '@/components/insights/loyalty/ProductHighlightStage.vue';
import PointsCount from '@/components/insights/loyalty/PointsCount.vue';

export default {
  name: 'Store',
  components: {
    loadingSpinner,
    noPermission,
    Multiselect,
    Count,
    LineChart,
    PieChart,
    ProductHighlightStage,
    ProductsTable,
    PointsCount
  },
  data() {
    return {
      environmentTag: null,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      productLicenses: {
        loyalty_licence_tag: null
      },         
      loadingInsightsData: false,
      loadingLeaderboardData: false,
      insightsData: {
        metrics: [{
          user_points_spent: 0,
          headcount_user_points_spent: 0,
          orders_amount: 0,
          orders_amount_of_products: 0
        }],
        headcount: { headcount_total: 0 },
        categories: []
      },
      showDownloadButtons: false,
      printingInsights: false,      
      selectedTeams: [],
      departments: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      companyPrimaryColor: null,
      lineChartSeries: [],
      pieChartSeries: []
    }
  },
  methods: {
    getInsightsData() {
      let params = {};
      params.teams = [];
            
      for(let t = 0; t < this.selectedTeams.length; t++) {
        params.teams.push(this.selectedTeams[t].team_id);
      }

      // Start the loader
      this.loadingInsightsData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      // Get the topics insights data
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/insights/store/' + dateFromAPI + '/' + dateToAPI, params)      
      .then(res => {
        // Set the insightsData
        this.insightsData = res.data.data;
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');        
        // Update the lineChartSeries
        this.lineChartSeries = [{ name: this.$t('common.Coints_spent_trend'), data: this.insightsData.chart }];
        // Update the lineChartSeries
        this.pieChartSeries = [{ name: this.$t('common.Category'), data: this.insightsData.categories }];        
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingInsightsData = false;
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;            
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    getDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departmentTeams')
      .then(res => {
        // Get departments and teams from API response        
        var departments = res.data.data.departments;
        var teams = res.data.data.teams;
        // Empty departments array
        this.departments = [];        
        // Loop through departments
        for(var d = 0; d < departments.length; d++) {
          // Push new department into departments array
          this.departments.push({
            department_id: departments[d].department_id,
            department_name: departments[d].department_name,
            teams: []
          });

          // Loop through teams
          for(var t = 0; t < teams.length; t++) {
            // If a team is part of a department, push the team into the teams array of this department
            if(departments[d].department_id === teams[t].department_id) {
              var index = this.departments.findIndex(i => i.department_id == departments[d].department_id);
              this.departments[index].teams.push({
                team_id: teams[t].team_id,
                team_name: teams[t].team_name  + ' (' + departments[d].department_name + ')'
              });
            }
          }
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
    
      link.setAttribute("download", "loyalty_insights_store_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    exportData() {      
      this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
      
      let exportFileName = 'loyalty-insights-store';

      if(!window.navigator.msSaveOrOpenBlob){
        // Blob navigator
        let url = null;
        // Set the output data and URL
        let outputData = JSON.stringify(this.insightsData);
        url = window.URL.createObjectURL(new Blob([outputData]));
        // Create a new 'a' element
        const link = document.createElement('a');
        // Set the generated URL as href of the new element
        link.href = url;
        // Set the download attribute
        link.setAttribute('download', exportFileName + '.json');
        // Append the link to the body
        document.body.appendChild(link);
        // Click the link
        link.click();
      } else {
        // Blob for Explorer 11
        let url = null;
        // Set the output data and URL        
        outpuData = JSON.stringify(this.topicInsightsData);
        url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.json');
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  async mounted() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.productLicenses = await this.fetchProduuctLicenseData();

    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);
    // Get platform permissions
    this.getPlatformPermissions();
    // Get the insights data
    this.getInsightsData();
    // Get the departments
    this.getDepartments();
  }
}
</script>